import React, { useState } from "react";
import "./expandingImage.css";

const ImageExpander = ({ src, alt = "Expandable", text = "" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (event) => {
    // Prevent closing when clicking on expanded text
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`image-container ${isExpanded ? "expanded" : ""}`}
      onClick={toggleExpand}
    >
      <div className="content-wrapper">
        <img src={src} alt={alt} className="expandable-image" />
        <div className={`image-text ${isExpanded ? "expanded-text" : ""}`}>
          {text}
        </div>
      </div>
      {isExpanded && (
        <div className="overlay" onClick={toggleExpand}></div>
      )}
    </div>
  );
};

export default ImageExpander;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Column } from "primereact/column";
import { isEmpty, toInteger } from "lodash";
import firebase from "../../config";
import { DataTable } from "primereact/datatable";
import { Button as PrimeButton } from "primereact/button";
import PulseLoader from "react-spinners/PulseLoader";
import { usePrevious } from "../../hooks/userPrevious";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";
import { DatePicker, TimePicker } from "antd";

const database = firebase.firestore();

const Regulations = () => {
  const teams = useSelector((state) => ({
    projects: state.team.projects,
    active: state.team.active,
    teams: state?.team?.teams,
  }));
  const teamProjects = Array.isArray(teams.projects) ? teams.projects.filter(itm=> !itm.data?.deleted) : []
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const { activeCompany } = useSelector((state) => state.auth.profile);
  const { profile } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [hour, setHour] = useState(0);
  const [startHours, setStartHours] = useState(0);
  const [startMinutes, setStartMinutes] = useState(0);
  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [minute, setMinute] = useState(0);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [task, setTask] = useState({ id: "choose task", name: "choose task" });
  const [taskLists, setTaskLists] = useState([]);
  const [regList, setRegList] = useState([]);
  const [, setTaskRowIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [assignee, setAssignee] = useState("");
  const [detail, setDetail] = useState("");
  const [t, setT] = useState({});
  const [d, setD] = useState({});
  const [updatingProject, setUpdatingProject] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)
  var newArray = [];

  const getRegData = () => {
    const data = [];
    setLoading(true);
    database
      .collection("companyDatabase").doc(activeCompany.companyId).collection("regulationRequest")
      .where("userId", "==", profile.id)
      .get()
      .then(async (res) => {
        const auto = res.docs.map((res) => res.data());
        const autoId = res.docs.map((res) => res.id);
        for (let i = 0; i < auto.length; i++) {
          data.push({ ...auto[i], id: autoId[i] });
        }
        setRegList(data);
        setLoading(false);
      });
  };

  const validate = () => {
    let valid = true
    const newProject = project ? JSON.parse(project) : project || selectedData.projectId;
    const givenDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const hoursToMunites = hour*60
    const totalTaskMinutes = hoursToMunites + minute
    const endHours = Number(startHours) + Number(hour)
    const endMinutes = Number(startMinutes) + Number(minute)

    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    const givenTime = new Date();
    givenTime.setHours(endHours, endMinutes, 0, 0);

    if(day===0 || day=== "" || month===0 || month==="" || year===0 || year===""){
      addNotification({
        message: `Invalid Date Please give valid date !`,
        level: notificationStates.error,
      })
      valid = false
      return
    }
    
    if(!newProject || newProject?.name === "choose Project"){
      addNotification({
        message: `Please Choose a Project`,
        level: notificationStates.error,
      })
      valid = false
      return
    }

    // if(!detail){
    //   addNotification({
    //     message: `Please write something in task details section...`,
    //     level: notificationStates.error,
    //   })
    //   valid = false
    //   return
    // }

    if(!assignee || assignee === "select a manager"){
      addNotification({
        message: `Please Choose Manager`,
        level: notificationStates.error,
      })
      valid = false
      return
    }

    if( year<2023 ){
      addNotification({
        message: "Please choose date after 2023 !",
        level: notificationStates.error,
      })
      valid = false
      return
    }

    if (givenDate > currentDate) {
      addNotification({
        message: `You can't add a regularization request for future date, please give current date or past date !`,
        level: notificationStates.error,
      })
      valid = false
      return
    }

    if (givenTime > endOfDay) {
      addNotification({
        message: `Adding ${hour} hours and ${minute} minute exceeds 24 hours from ${startHours}:${startMinutes}!"`,
        level: notificationStates.error,
      })
      valid = false
      return
    }

    if (totalTaskMinutes < 1) {
      addNotification({
        message: `task time is less than 1 minutes, please enter atleast 1 minute...!"`,
        level: notificationStates.error,
      })
      valid = false
      return
    }

    if (startHours === "" || startMinutes === "") {
      addNotification({
        message: `invalid start time please enter any value for start time!"`,
        level: notificationStates.error,
      })
      valid = false
      return
    }
    
    return valid
  }

  const check24HoursLimit = async () => {
    let timeLimit = true;
    try {
      const dateId = `${day}:${month}:${year}`;
      const teamId = teams.active.activeTeamid;
      const userId = profile.id;
      const givenDate = `${day}-${month}-${year}`;
      const today = new Date();
      const currentDay = String(today.getDate());
      const currentMonth = String(today.getMonth() + 1);
      const currentYear = today.getFullYear();
      const currentDate = `${currentDay}-${currentMonth}-${currentYear}`;
      const currentHours = today.getHours();
      const currentMinutes = today.getMinutes();
      const totalMinutesSinceMidnight = currentHours * 60 + currentMinutes;
      const addingMinutes = Number(hour) * 60 + Number(minute);
      let totalMinuteToAdd = addingMinutes ;

      const snap = await database
        .collection("memberData")
        .doc(teamId)
        .collection("daysData")
        .doc(userId)
        .collection("dateId")
        .doc(dateId)
        .get();

      const data = snap.data();
      let dbTotalMinutes = snap.exists
        ? data.totalHours * 60 + data.totalMinutes
        : 0;
      totalMinuteToAdd = dbTotalMinutes + addingMinutes;

      if (givenDate === currentDate) {
        if (totalMinuteToAdd > totalMinutesSinceMidnight) {
          addNotification({
            message: `your logged time is greater than current time... !`,
            level: notificationStates.error,
          });
          timeLimit = false;
          return;
        }
      } else {
        let totalTime = totalMinuteToAdd / 60;
        if (totalTime > 24) {
          addNotification({
            message: `your regularization time is excedding 24 hours... !`,
            level: notificationStates.error,
          });
          timeLimit = false;
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return timeLimit;
  };

  // Create new regulation
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if(!validate()) {
      setIsSubmitting(false);
      return
    }

    setIsSubmitting(true);
    const timeLimitCheck = await check24HoursLimit()
    if(!timeLimitCheck) {
      setIsSubmitting(false);
      return
    }

    setIsSubmitting(true);
    let endHours = Number(startHours) + Number(hour)
    let endMinutes = Number(startMinutes) + Number(minute)

    let endTime = {
      endHours, 
      endMinutes
    }
    console.log('fffffffffff', task)
    let finalData = {
      userId: profile.id,
      companyID: activeCompany.companyId,
      projectId: d?.k,
      taskName: task?.name === "choose task" ? "Choose Task" : task?.name || "",
      taskID: task?.id || "",
      taskDetails: detail,
      screenshots: {},
      assignee: assignee !== "" ? assignee : asg,
      taskHours: hour === "" ? 0 : parseInt(hour),
      taskMinutes: minute === "" ? 0 : parseInt(minute),
      idleMinutes: 0,
      status: "Pending",
      typeLog: "Manual",
      startTime: `${parseInt(year)}-${parseInt(month)}-${parseInt(day)}`,
      requestDate: new Date(),
      endTime: endTime,
      startHours: parseInt(startHours),
      startMinutes: parseInt(startMinutes),
      teamId: teams.active.activeTeamid
    };

    return database
      .runTransaction(async (transaction) => {
        const ref = database.collection("companyDatabase").doc(finalData.companyID).collection("regulationRequest").doc();
        const memb = database
          .collection("membersDatabase")
          .doc(assignee !== "" ? assignee : asg);
        await transaction?.set(ref, finalData);
        await transaction?.update(memb, {
          regularizationRequests: firebase.firestore.FieldValue.arrayUnion(
            ref?.id
          ),
        });
      })
      .then((res) => {
        toggle();
        addNotification({
          message: "Request submitted successfully",
          level: notificationStates.success,
        });
        getRegData();
        setProject("")
        setTask("")
        setDetail("")
        setAssignee("")
        setDay(0)
        setMonth(0)
        setYear(0)
        setHour(0)
        setMinute(0)
        setIsSubmitting(false)
      })
      .catch((error) => {
        addNotification({
          message: "Request submission failed",
          level: notificationStates.error,
        });
        console.log(error);
      })
  };

  // handle update regulation
  const handleUpdate = async (e) => {
    e.preventDefault();
    if(!validate()) {
      setIsSubmitting(false);
      return
    }

    setIsSubmitting(true);
    const timeLimitCheck = await check24HoursLimit()
    if(!timeLimitCheck) {
      setIsSubmitting(false);
      return
    }

    setIsSubmitting(true);

    let endHours = Number(startHours) + Number(hour)
    let endMinutes = Number(startMinutes) + Number(minute)

    let endTime = {
      endHours, 
      endMinutes
    }

    let finalData = {
      userId: profile.id,
      companyID: activeCompany.companyId,
      projectId: d?.k || selectedData?.projectId,
      taskName: task?.name === "choose task" ? "Choose Task" : task?.name,
      taskID: t?.id || selectedData?.taskID || "",
      taskDetails: detail ||"",
      screenshots: {},
      assignee: assignee !== "" ? assignee : asg || "",
      taskHours: hour === "" ? 0 : parseInt(hour),
      taskMinutes: minute === "" ? 0 : parseInt(minute),
      idleMinutes: 0,
      status: "Pending",
      typeLog: "Manual",
      startTime: `${parseInt(year)}-${parseInt(month)}-${parseInt(day)}`,
      requestDate: new Date(),
      endTime,
      startHours: parseInt(startHours),
      startMinutes: parseInt(startMinutes)
    };
    const ref = database.collection("companyDatabase").doc(finalData.companyID).collection("regulationRequest").doc(selectedData.id);
    const membReg = database.collection("membersDatabase")
    return await ref?.update(finalData).then(async (res) => {
          if(!assignee || assignee === selectedData?.assignee){
            toggleUpdate();
            addNotification({
              message: "Request Updated successfully",
              level: notificationStates.success,
            });
            getRegData();
            setProject("")
            setTask("")
            setDetail("")
            setAssignee("")
            setDay(0)
            setMonth(0)
            setYear(0)
            setHour(0)
            setMinute(0)
            setIsSubmitting(false)
            return;
        }
         await database.runTransaction(async (transaction) => {
          await transaction?.update(membReg.doc(selectedData.assignee), {
            regularizationRequests: firebase.firestore.FieldValue.arrayRemove(selectedData.id),
          });
          await transaction?.update(membReg.doc(assignee), {
            regularizationRequests: firebase.firestore.FieldValue.arrayUnion(selectedData.id),
          });
        })
      
      toggleUpdate();
      addNotification({
        message: "Request Updated successfully",
        level: notificationStates.success,
      });
      getRegData();
      setProject("")
      setTask("")
      setDetail("")
      setAssignee("")
      setDay(0)
      setMonth(0)
      setYear(0)
      setHour(0)
      setMinute(0)
      setIsSubmitting(false)
    });
  };

  // Delete regulation
  const handleDelete = async () => {
    setIsDeleting(true)
    const ref = database.collection("companyDatabase").doc(activeCompany.companyId).collection("regulationRequest").doc(selectedData.id);
    const membReg = database.collection("membersDatabase")
    return await ref?.delete().then((res) => {
      database.runTransaction(async(transaction) => {
        await transaction?.update(membReg.doc(selectedData.assignee), {
          regularizationRequests: firebase.firestore.FieldValue.arrayRemove(selectedData.id),
        });
      })
      toggleUpdate();
      addNotification({
        message: "Request deleted successfully",
        level: notificationStates.success,
      });
      getRegData();
      setIsDeleting(false)
    });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleUpdate = () => {
    setUpdateModal(!updateModal);
  };

  const handleModalClosed = () => {
    // setTaskLists([])
    setD({})
    setT({})
    setProject("")
    setTask("")
    setDetail("")
    setAssignee("")
    setDay(0)
    setMonth(0)
    setYear(0)
    setHour(0)
    setMinute(0)
    setStartHours(0)
    setStartMinutes(0)
  }

  const fieldIndex = (rowData, column) => {
    setTaskRowIndex(column.rowIndex + 1);
    return column.rowIndex + 1 + "";
  };

  const handleProjectTasks = async (e) => {
    setLoading(true);
    const d = JSON.parse(e);
    if(d.name === "choose Project"){
      setTask("");
      setTaskLists([]);
      setLoading(false);
      return
    }
    await database
      .collection("taskDatabase")
      .where("ProjectID", "==", d?.k)
      .get()
      .then((res) => {
        let lists = res?.docs?.map((r) => r?.data());
        const listId = res?.docs?.map((r) => r?.id);
        let listsWithId = [];
        for (let i = 0; i < lists.length; i++) {
          if (lists[i].Assignee === profile?.id) {
            listsWithId.push({ ...lists[i], id: listId[i] });
          }
        }
        setTaskLists(listsWithId.filter(r=>!r.Deleted));
      });
    setLoading(false);
  };

  // onclicking navigate to task page function
  const navigate = (data) => {
    if (data?.status !== "Declined") {
      let project = teamProjects.find(itm => itm.id === data.projectId)
      let date = data.startTime.split("-")
      handleProjectTasks(JSON.stringify({k: project.id, name: "????"}))
      setDay(date[2])
      setMonth(date[1])
      setYear(date[0])
      setHour(data.taskHours)
      setMinute(data.taskMinutes)
      setUpdatingProject(project)
      setSelectedData(data);
      toggleUpdate();
      setAssignee(data?.assignee)
      setTask({id: data?.taskID, name: data?.taskName})
      setDetail(data?.taskDetails)
      setStartHours(Number(data?.startHours) || 0)
      setStartMinutes(Number(data?.startMinutes) || 0)
    }
  };

  useEffect(() => {
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
    if (!isEmpty(teams.active)) {
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: activeCompany.companyId },
      });
      getRegData();
    }
  }, []);

  const assigneeTempelate = (rowData) => {
    return (
      members?.length > 0 &&
      members?.filter((a) => {
        if (a.userUID == rowData?.assignee) {
          return a;
        }
      })[0]?.userName
    );
  };

  const timeTempelate = (rowData) => {
    return `${rowData?.taskHours}h ${rowData.taskMinutes}m`;
  };

  const ReportDateTempelate = (rowData) => {
      let dateObject = rowData.requestDate.toDate()
      var day = dateObject.getDate();
      var month = dateObject.getMonth() + 1;
      var year = dateObject.getFullYear();

      day = day < 10 ? '0' + day : day;
      month = month < 10 ? '0' + month : month;

      let dateString = day + '-' + month + '-' + year;
      return dateString
  }

  const TaskDateTempelate = (rowData) => {
    var dateStringtemp = rowData.startTime;
    var parts = dateStringtemp.split('-');
    var yeartemp = parseInt(parts[0]);
    var monthtemp = parseInt(parts[1]) - 1;
    var daytemp = parseInt(parts[2]);

    // Create a Date object
    var dateObject = new Date(yeartemp, monthtemp, daytemp);

    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1;
    var year = dateObject.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    let dateString = day + '-' + month + '-' + year;
    return dateString

  }

  const projectTempelate = (rowData) => {
    return (
      teams.projects?.length > 0 &&
      teams.projects?.filter((a) => {
        if (a?.id == rowData?.projectId) {
          return a;
        }
      })[0]?.data?.project_name
    );
  };

  useEffect(() => {
    Object.entries(teams.projects).map((m) => {
      !m[1]?.data?.deleted === true && setProjects(prev=> [...prev,m[1]]);
    });

  if (teamProjects.length !== 0) {
    database
      .collection("taskDatabase")
      .where("ProjectID", "==", teamProjects[0].id)
      .get()
      .then((res) => {
        let lists = res?.docs?.map((r) => r?.data());
        const listId = res?.docs?.map((r) => r?.id);
        let listsWithId = [];
        for (let i = 0; i < lists.length; i++) {
          if (lists[i].Assignee === profile?.id) {
            listsWithId.push({ ...lists[i], id: listId[i] });
          }
        }
        const filteredListWithId = listsWithId.filter(itm=> !itm.Deleted)
        setTaskLists(filteredListWithId);
      });
  }
}, [teams.projects]);

  var asg = "";
  if (members?.length > 0) {
    // el.role === "Head"
    newArray = members?.filter(function (el) {
      return el.role === "Admin" || el.role === "Manager";
    });
    newArray = newArray?.filter(user=>{
      const hasTeam = user?.teams?.find((team)=>
        team?.teamId === teams.active.activeTeamid
      );
      return Boolean(hasTeam);
    })
    asg = newArray?.length > 0 ? newArray[0].userUID : "";
  }

  return (
    <>
      <div style={{ paddingTop: "5rem!important" }}>
        <Container className="pt-md-2" fluid>
          <PrimeButton className="add-request-btn" onClick={toggle}>
            {" "}
            + Add Request
          </PrimeButton>
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              <PulseLoader color="#36d7b7" />
            </div>
          ) : (
            <Card className="bg-secondary shadow mt-2">
              <DataTable
                className="mt-2 task-table task-page-table"
                responsiveLayout="scroll"
                value={regList}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                style={{ cursor: "pointer" }}
                rows={20}
                rowsPerPageOptions={[10, 20, 50]}
                onRowClick={(e) => navigate(e?.data)}
              >
                <Column
                  field="#"
                  header="ID"
                  body={fieldIndex}
                  className="first-col td-start"
                ></Column>
                <Column
                  field="projectId"
                  header="Project"
                  body={projectTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="taskName"
                  header="Task Name"
                  className="td-start"
                  elipsis
                  sortable
                ></Column>
                <Column
                  field="taskTime"
                  header="Task Time"
                  body={timeTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="taskDate"
                  header="Task Date"
                  body={TaskDateTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="reportDate"
                  header="Report Date"
                  body={ReportDateTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="assignee"
                  header="Assignee"
                  body={assigneeTempelate}
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  sortable
                  className="td-start"
                ></Column>
              </DataTable>
            </Card>
          )}
        </Container>
      </div>

      {/* Modal for creating regulation */}
      <Modal isOpen={modal} toggle={toggle} onClosed={handleModalClosed} className="regulationModal">
        <ModalHeader toggle={toggle}>Regularization Form</ModalHeader>
        <ModalBody className="p-5">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="exampleNumber">Project</Label>
              <Input
                type="select"
                name="projectName"
                id="project"
                value={project ? project : { k: "key", value: "choose a project" }}
                onChange={(e) => {
                  setT({});
                  setProject(e.target.value);
                  setD(JSON.parse(e.target.value));
                  handleProjectTasks(e.target.value);
                }}
              >
                <option
                  key={"key"}
                  value={JSON.stringify({
                    k: "choose project",
                    name: "choose Project",
                  })}
                >
                  Choose Project
                </option>
                {Object.entries(teams?.projects).map((m) => {
                  return (
                    !m[1]?.data?.deleted === true && (
                      <option
                        key={m[1]?.id}
                        value={JSON.stringify({
                          k: m[1]?.id,
                          name: m[1]?.data?.project_name,
                        })}
                      >
                        {m[1]?.data?.project_name}
                      </option>
                    )
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleTask">Task</Label>
                <Input
                  type="select"
                  name="taskName"
                  id="task"
                  value={task ? JSON.stringify(task) : 'Choose Task'}
                  onChange={(e) => {
                    setTask(JSON.parse(e.target.value));
                    setT(JSON.parse(e.target.value));
                  }}
                >
                  <option key={"key"} value={JSON.stringify({ id: "choose task", name: "choose task" })}>
                    Choose a Task
                  </option>
                  {project && taskLists?.map((m) => {
                    return (
                      <>
                        <option
                          key={m?.id}
                          value={JSON.stringify({ id: m?.id, name: m?.Title })}
                        >
                          {m?.Title}
                        </option>
                      </>
                    );
                  })}
                </Input>
            </FormGroup>

            <FormGroup>
              <Label for="exampleNumber">Task Details</Label>
              <Input
                type="text"
                name="details"
                placeholder="Please enter task details here..."
                id="text"
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
            </FormGroup>
            {newArray?.length > 0 && (
              <FormGroup>
                <Label for="exampleNumber">Manager</Label>
                <Input
                  type="select"
                  name="assignee"
                  id="task"
                  // value={assignee !== "" ? assignee : asg}
                  value={assignee !== "" ? assignee : "select a manager"}
                  onChange={(e) => setAssignee(e.target.value)}
                >
                  <option key={"key"} value={"select a manager"}>
                    Select a Manager
                  </option>
                  {newArray?.map((m) => {
                    return (
                      <option key={m?.userUID} value={m?.userUID}>
                        {m?.userName}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            )}
            <FormGroup>
              <Label style={{width: "100%"}} for="exampleNumber">Date</Label>
              <DatePicker  
                onChange={(date)=>{
                  if(!date) return;
                  setDay(date.day()+1)
                  setMonth(date.month()+1)
                  setYear(date.year())
                }}
                style={{width: "100%", height: "46px"}}
              />
            </FormGroup>
            {/* time to complete task */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleNumber">Hours</Label>
                  <Input
                    type="number"
                    name="hour"
                    id="number"
                    min="0"
                    value={hour}
                    onChange={(e) => setHour(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleNumber">Minutes</Label>
                  <Input
                    type="number"
                    name="minute"
                    id="number"
                    min="0"
                    max="60"
                    value={minute}
                    onChange={(e) => setMinute(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label for="exampleNumber">Task Start Time</Label>
            <FormGroup>
              <TimePicker  
                use12Hours
                showSecond={false}
                onChange={(time)=>{
                  if(!time) return;
                  setStartHours(time.toDate().getHours());
                  setStartMinutes(time.minute());
                }}
                style={{width: "100%", height: "46px"}}
              />
            </FormGroup>
            <Button color="primary" disabled ={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Modal for updating and deleting regulation */}
      <Modal
        isOpen={updateModal}
        toggle={toggleUpdate}
        className="regulationModal"
        style={{ minWidth: "55%" }}
        onClosed={handleModalClosed}
      >
        <ModalHeader toggle={toggleUpdate}>
          Update/Delete Regularization Form
        </ModalHeader>
        <ModalBody className="p-5">
          <Row>
            <Col md={6}>
              <h4>
                Company :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {activeCompany?.companyName}
                </span>
              </h4>
              <h4>
                Project :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {teams.projects?.length > 0 &&
                    teams.projects?.filter((a) => {
                      if (a?.id == selectedData?.projectId) {
                        return a;
                      }
                    })[0]?.data?.project_name}
                </span>
              </h4>
              <h4>
                Task Name :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData.taskName}
                </span>
              </h4>
              <h4>
                Task Time :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData?.taskHours}h {selectedData?.taskMinutes}m
                </span>
              </h4>
              <h4>
                Start Time :{" "}
                <span style={{ fontWeight: "normal" }}>
                  {selectedData?.startHours}h {selectedData?.startMinutes}m
                </span>
              </h4>
            </Col>
            <Col md={6}>
              <Form onSubmit={handleUpdate}>
                <FormGroup>
                  <Label for="exampleNumber">Project</Label>
                  {updatingProject && (
                    <Input
                      type="select"
                      name="projectName"
                      id="project"
                      value={
                        project ? project : JSON.stringify({
                            k: updatingProject.id,
                            name: updatingProject?.data?.project_name,
                          })
                      }
                      onChange={(e) => {
                        setT({});
                        setProject(e.target.value);
                        setD(JSON.parse(e.target.value));
                        handleProjectTasks(e.target.value);
                      }}
                    >
                      <option
                        key={"key"}
                        value={JSON.stringify({
                          k: "choose project",
                          name: "choose Project",
                        })}
                      >
                        Choose Project
                      </option>
                      {Object.entries(teams.projects).map((m) => {
                        return (
                          !m[1]?.data?.deleted === true && (
                            <option
                              key={m[1]?.id}
                              value={JSON.stringify({
                                k: m[1]?.id,
                                name: m[1]?.data?.project_name,
                              })}
                            >
                              {m[1]?.data?.project_name}
                            </option>
                          )
                        );
                      })}
                    </Input>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="exampleTask">Task</Label>
                    <Input
                      type="select"
                      name="taskName"
                      id="task"
                      value={task ? JSON.stringify(task) : ''}
                      onChange={(e) => {
                        setTask(JSON.parse(e.target.value));
                        setT(JSON.parse(e.target.value));
                      }}
                    >
                    <option key={"key"} value={JSON.stringify({ id: "choose task", name: "choose task" })}>
                      Choose a Task
                    </option>
                      {taskLists?.map((m) => {
                        return (
                          <>
                            <option
                              key={m?.id}
                              value={JSON.stringify({ id: m?.id, name: m?.Title })}
                            >
                              {m?.Title}
                            </option>
                          </>
                        );
                      })}
                    </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleNumber">Task Details</Label>
                  <Input
                    type="text"
                    name="details"
                    placeholder="Please enter task details here..."
                    id="text"
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                  />
                </FormGroup>
                {newArray?.length > 0 && (
                  <FormGroup>
                    <Label for="exampleNumber">Assignee</Label>
                    <Input
                      type="select"
                      name="assignee"
                      id="task"
                      // value={assignee !== "" ? assignee : selectedData.assignee}
                      value={assignee !== "" ? assignee : selectedData?.assignee}
                      onChange={(e) => setAssignee(e.target.value)}
                    >
                      <option key={"key"} value={"select a manager"}>
                        Select a Manager
                      </option>
                      {newArray?.map((m) => {
                        return (
                          <option key={m?.userUID} value={m?.userUID}>
                            {m?.userName}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                )}
                <Row>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Date</Label>
                      <Input
                        type="number"
                        name="day"
                        id="number"
                        min="1"
                        max="31"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Month</Label>
                      <Input
                        type="number"
                        name="month"
                        id="number"
                        min="1"
                        max="12"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    {" "}
                    <FormGroup>
                      <Label for="exampleNumber">Year</Label>
                      <Input
                        type="number"
                        name="year"
                        id="number"
                        min="2023"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleNumber">Hours</Label>
                      <Input
                        type="number"
                        name="hour"
                        id="number"
                        min="0"
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleNumber">Minutes</Label>
                      <Input
                        type="number"
                        name="minute"
                        id="number"
                        min="0"
                        max="60"
                        value={minute}
                        onChange={(e) => setMinute(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Label for="exampleNumber">Task Start Time</Label>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleNumber">Hours</Label>
                      <Input
                        type="number"
                        name="hour"
                        id="number"
                        min="0"
                        value={startHours}
                        onChange={(e) => setStartHours(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleNumber">Minutes</Label>
                      <Input
                        type="number"
                        name="minute"
                        id="number"
                        min="0"
                        max="60"
                        value={startMinutes}
                        onChange={(e) => setStartMinutes(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {selectedData.status === "Pending" && (
                  <>
                    <Button color="primary" disabled ={isSubmitting}>{isSubmitting ? 'Updating...' : 'Update'}</Button>
                    <Button onClick={handleDelete} disabled={isDeleting} color="danger">
                      {isDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Regulations;

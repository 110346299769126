import moment from "moment";
import firebase from "../config";
import map from "lodash/map";
import { reject } from "lodash";
import teamService from '../services/team';
import groupBy from 'lodash/groupBy';
const database = firebase.firestore();


/**
 * Gets all team members from all teams
 * @param {*} teams 
 * @returns 
 */
async function getAllTeamMembersData(payload) {
    let { teams, filterBy } = payload
    try {
        let tempTeams = [...teams]
        let finalizeTeamMembers = []

        let count = 0;
        do {
            let teamId = tempTeams[count].activeTeamid
            let teamName = tempTeams[count].Team
            let { success, data } = await teamService.getTeamMembers(teamId)
            if (success) {
                if (data.length > 0) {
                    map(data, (member) => {
                        if(member.deleted !== true)
                        finalizeTeamMembers.push({ teamId, teamName, ...member })
                    })
                }
            }
            count = count + 1
        } while (count < tempTeams.length)

        let groupedData = groupBy(finalizeTeamMembers, "userEmailid")

        let allMembers = []

        allMembers = map(groupedData, (group, index) => {
            let obj = {}
            obj["userUID"] = group[0].userUID
            obj["userEmail"] = group[0].userEmailid
            obj["userName"] = group[0].userName
            obj["role"] = group[0].role
            obj["rates"] = group[0].rates
            if (!filterBy) {
                obj["teams"] = group.map(fnlItm => ({ teamId: fnlItm.teamId, teamName: fnlItm.teamName, role: fnlItm.userType }))
            } else {
                let preparedTms = []
                if (filterBy?.teams && filterBy?.role) {
                    let isMember = group.filter(itm => (itm.userType == filterBy?.role))
                    preparedTms = isMember.filter(itm => filterBy?.teams.includes(itm.teamId)).map(fnlItm => ({ teamId: fnlItm.teamId, teamName: fnlItm.teamName, role: fnlItm.userType }))
                }
                else if (filterBy?.teams) {
                    preparedTms = group.filter(itm => filterBy?.teams.includes(itm.teamId)).map(fnlItm => ({ teamId: fnlItm.teamId, teamName: fnlItm.teamName, role: fnlItm.userType }))
                }
                else if (filterBy?.role) {
                    preparedTms = group.filter(itm => (itm.userType == filterBy?.role)).map(fnItm => ({ teamId: fnItm.teamId, teamName: fnItm.teamName, role: fnItm.userType }))
                }
                obj['teams'] = [...preparedTms]
            }
            return { ...obj }
        })
        if (filterBy) {
            let temp = allMembers.filter(fm => fm.teams.length > 0)
            allMembers = [...temp]
        }
        return { success: true, data: [...allMembers] }
    } catch (e) {
        return { success: false, data: e }
    }
}

/**
 * Create new team with or without members
 * @param {*} payload 
 * @returns 
 */
async function createTeamWithMembers(payload) {
    try {
        const { teamName, ownedBy, createdBy, members } = payload

        const cityRef = database.collection('companyDatabase').doc(ownedBy)
        const doc = await cityRef.get();
        const companyTeam = doc.data();

        let teamDBRef = database.collection("teamDatabase").doc(),
            memberDBRef = database.collection("membersDatabase").doc(createdBy.userId),

            memberCompanyListRef = memberDBRef.collection('companyList').doc(ownedBy).collection('teamList').doc(teamDBRef.id),
            // ownerDBRef = memberDBRef.collection('teamList').doc(teamDBRef.id),
            companyTeamsRef = database.collection('companyDatabase').doc(ownedBy).collection('teams').doc(teamDBRef.id);
            let companyDBRef = database.collection('companyDatabase').doc(ownedBy);



        let batch = database.batch(),
            memberDetails = {},
            membersIDs = [];
            // 1. Extract admins from the company data
            const adminUserIds = companyTeam.admins || [];

            
        // 2. For each admin, fetch member details and add to memberDetails + member's teamList
        for (const adminId of adminUserIds) {
            const adminDoc = await database.collection('membersDatabase').doc(adminId).get();
            if (adminDoc.exists) {
                const adminData = adminDoc.data();
                const email = adminData.accountEmailid;
                const name = adminData.accountFullName;

                memberDetails[adminId] = {
                    userEmailid: email,
                    userName: name,
                    userType: 'Admin' // assuming admin role
                };

                membersIDs.push(adminId);

                // Add to admin's teamList under companyList
                const adminTeamListRef = database.collection("membersDatabase").doc(adminId)
                    .collection('companyList').doc(ownedBy)
                    .collection('teamList').doc(teamDBRef.id);

                batch.set(adminTeamListRef, {
                    Role: 'Admin',                   
                    Team: teamName,
                    activeTeamid: teamDBRef.id,
                    email: email
                }, { merge: true });
            }
        }

        membersIDs = members.map(i => i.userUID);

        const data = {
            teamId: teamDBRef.id,
            members: membersIDs,
        }
        
        // Sets a creator as a default member 
        memberDetails[createdBy.userId] = {
            userEmailid: createdBy.email,
            userName: createdBy.name,
            userType: createdBy.role
        }

        members.map((mem) => {

            memberDetails[mem.userUID] = {
                userEmailid: mem.userEmail,
                userName: mem.userName,
                userType: mem?.role
            }

            // ** Adds team details to individual member's account relevant to company
            let memTeamListRef = database.collection("membersDatabase").doc(mem.userUID).collection('companyList').doc(ownedBy).collection('teamList').doc(teamDBRef.id);
            batch.set(memTeamListRef, {
                Tasks: {},
                Role: mem?.role,
                Team: teamName,
                completedTasks: {},
                email: mem.userEmail
            }, { merge: true })

        })

        // ** Adds to the teamList under the companyList in CompanyDatabase
        batch.set(memberCompanyListRef, {
            Role: createdBy.role,
            Team: teamName,
            email: createdBy.email,
            activeTeamid: teamDBRef.id
        }, { merge: true });

        // ** Adds to teamDb with memberDetails in teamDatabase
        batch.set(teamDBRef, {
            teamName: teamName,
            ownedBy: ownedBy,
            createdBy: createdBy.userId,
            memberDetails: { ...memberDetails }
        }, { merge: true });

        // ** Updates to the companyDatabase
        batch.set(companyTeamsRef, {
            teamId: teamDBRef.id,
            // members: membersIDs.length > 0 ? membersIDs : {},
            ...(membersIDs.length > 0 ? { members: membersIDs } : {})
        }, { merge: true })

        if(companyTeam.teams){
            const newData = companyTeam.teams;
            newData.push(data);
            batch.update(companyDBRef, {
                teams: firebase.firestore.FieldValue.arrayUnion(teamDBRef.id),
            })
        }else {
             batch.update(companyDBRef, {
            teams: [teamDBRef.id],
        })
        }

        return batch.commit().then(() => {
            return { success: true, data: "Team created successfully!" }
        })

    } catch (e) {
        return { success: false, data: e }
    }

}


/**
 * Edit team details with members
 * @param {*} payload 
 * @returns 
 */
async function editTeamWithMembers(payload) {
    try {

        const { teamName, teamId, createdBy, members, ownedBy, shallRemove } = payload

        let teamDBRef = database.collection("teamDatabase").doc(teamId);
        // let ownerDBRef = database.collection("membersDatabase").doc(createdBy.userId).collection('teamList').doc(teamId)
        let memberCompanyListRef = database.collection("membersDatabase").doc(createdBy.userId).collection('companyList').doc(ownedBy).collection('teamList').doc(teamId)
        let companyTeamsRef = database.collection('companyDatabase').doc(ownedBy).collection('teams').doc(teamDBRef.id);
        let batch = database.batch()
        let memberDetails = {};
        let membersIDs = [];
        membersIDs = members.map(i => i.userUID);

        // Sets a editor as a default member 
        memberDetails[createdBy.userId] = {
            userEmailid: createdBy.email,
            userName: createdBy.name,
            userType: createdBy.role
        }

        if (members.length > 0) {
            members.map(mem => {
                memberDetails[mem.userUID] = {
                    userEmailid: mem.userEmail,
                    userName: mem.userName,
                    userType: mem.role
                }

                // ** If user is not the owner of the team
                if (mem.userUID != createdBy.userId) {
                    // ** Updates team details to individual member's account

                    let memTeamListRef = database.collection("membersDatabase").doc(mem.userUID).collection('companyList').doc(ownedBy).collection('teamList').doc(teamId);
                    batch.set(memTeamListRef, {
                        Role: mem?.role,
                        Team: teamName,
                        email: mem.userEmail
                    }, { merge: true })

                    // let memRef = database.collection("membersDatabase").doc(mem.userUID).collection('teamList').doc(teamId)
                    // batch.set(memRef, {
                    //     Role: mem.role,
                    //     Team: teamName,
                    //     email: mem.userEmail
                    // }, { merge: true })
                }
            })
        }



        // ** Update to the collection of the user that created this team
        // batch.update(ownerDBRef, { Team: teamName })

        // ** Update to the collection of the user that created this team
        batch.update(memberCompanyListRef, { Team: teamName })

        // ** Updates to teamDb with memberDetails
        batch.update(teamDBRef, {
            teamName: teamName,
            memberDetails: { ...memberDetails }
        })

        // ** Updates to the companyDatabase
        if (membersIDs.length > 0) {
            batch.set(companyTeamsRef, {
                members: membersIDs
            }, { merge: true })
        }

        if (shallRemove.length > 0) {
            shallRemove.map(srId => {
                // ** Removes team details from individual member's account
                // let srRef = database.collection("membersDatabase").doc(srId).collection('teamList').doc(teamId)
                let srListRef = database.collection("membersDatabase").doc(srId).collection('companyList').doc(ownedBy).collection('teamList').doc(teamId)
                batch.delete(srListRef)
                // batch.delete(srRef)
            })
        }


        return batch.commit().then(() => {
            return { success: true, data: "Team updated successfully!" }
        }).catch(e => {
            return { success: false, data: e }
        })

    } catch (e) {
        return { success: false, data: e }
    }

}


/**
 * Deletes team from database 
 * @param {*} payload 
 * @returns 
 */
async function deleteTeam(payload) {
    try {
        const { selectedTeam, ownerId, companyId } = payload
        const { team, teamName, members } = selectedTeam

        let teamDBRef = database.collection("teamDatabase").doc(team);
        // let ownerDBRef = database.collection("membersDatabase").doc(ownerId).collection('teamList').doc(team)
        let ownerDBListRef = database.collection("membersDatabase").doc(ownerId).collection('companyList').doc(companyId).collection('teamList').doc(team)
        let companyTeamsRef = database.collection('companyDatabase').doc(companyId).collection('teams').doc(team);
        let batch = database.batch()

        // ** Deletes team from teamDatabase
        batch.delete(teamDBRef)
        // batch.delete(ownerDBRef)
        batch.delete(ownerDBListRef)
        batch.delete(companyTeamsRef)

        members.map(mem => {
            // ** Deletes team details from individual member's account
            // let memRef = database.collection("membersDatabase").doc(mem.userUID).collection('teamList').doc(team)
            let memListRef = database.collection("membersDatabase").doc(mem.userUID).collection('companyList').doc(companyId).collection('teamList').doc(team)
            batch.delete(memListRef)
            // batch.delete(memRef)
        })

        return batch.commit().then(() => {
            return { success: true, data: "Team deleted successfully!" }
        })

    } catch (e) {
        return { success: false, data: e }
    }

}


/**
 * Updates individual member's details
 * @param {*} payload 
 * @returns 
 */
async function updateMemberDetails(payload) {
    try {
        let { name, email, role, selectedTeam, teamDetails, oldTeam, userUID, companyId } = payload


        let batch = database.batch()

        // Declarations according to selected team (new updated team)
        const teamRef = database.collection('teamDatabase').doc(selectedTeam)
        const memberDBRef = database.collection('membersDatabase').doc(userUID);
        // const memberTeamListRef = memberDBRef.collection('teamList').doc(selectedTeam)
        const memberCompanyTeamListRef = memberDBRef.collection("companyList").doc(companyId).collection("teamList").doc(selectedTeam);

        if (!oldTeam) {

            // Updates user details in teamDatabase
            batch.update(teamRef, {
                [`memberDetails.${userUID}`]: {
                    userEmailid: email,
                    userName: name,
                    userType: role
                }
            })
            // Updates team details in membersDB
            // batch.update(memberTeamListRef, { Role: role, email: email })
            // batch.update(memberCompanyTeamListRef,)

        } else {

            // ** Removes details from old team

            // Remove member from the old team
            let oldTeamRef = database.collection('teamDatabase').doc(oldTeam)
            batch.update(oldTeamRef, { [`memberDetails.${userUID}`]: firebase.firestore.FieldValue.delete() })

            // Removes from old team - companyDatabase
            let oldCompanyTeamRef = database.collection('companyDatabase').doc(companyId).collection('teams').doc(oldTeam)
            batch.update(oldCompanyTeamRef, {
                members: firebase.firestore.FieldValue.arrayRemove(userUID)
            })

            // Removes from memberDB
            // let oldMemTeamListRef = memberDBRef.collection('teamList').doc(oldTeam)
            let oldCompanyTeamListRef = memberDBRef.collection("companyList").doc(companyId).collection('teamList').doc(oldTeam)
            // batch.delete(oldMemTeamListRef)
            batch.delete(oldCompanyTeamListRef)


            // ** Adds details in new team
            batch.set(teamRef, {
                memberDetails: {
                    [userUID]: {
                        userEmailid: email,
                        userName: name,
                        userType: role
                    }
                }
            }, { merge: true })

            // ** Add new team to member's account inside companyList & teamList
            // batch.set(memberTeamListRef, {
            //     Role: role,
            //     Team: teamDetails?.label,
            //     email: email
            // }, { merge: true })
            batch.set(memberCompanyTeamListRef, {
                Role: role,
                Team: teamDetails?.label,
                email: email
            }, { merge: true })

            // Adds to new team in companyDatabase
            let newCompanyTeamRef = database.collection('companyDatabase').doc(companyId).collection('teams').doc(selectedTeam)
            batch.update(newCompanyTeamRef, {
                members: firebase.firestore.FieldValue.arrayUnion(userUID)
            })


        }

        return batch.commit().then(() => {
            return { success: true, data: 'Member details updated successfully' }
        }).catch(e => ({ success: false, data: e }))

    } catch (e) {
        return { success: false, data: e }
    }
}

/**
 * Deletes user from selected company
 * @param {*} payload 
 * @returns 
 */
async function deleteMember(payload) {
    try {

        let { userUID, userName, userEmail, teams, companyId } = payload

        const memberCompanyRef = database.collection('membersDatabase').doc(userUID).collection('companyList').doc(companyId);

        let batch = database.batch()

        batch.delete(memberCompanyRef);

        if (teams.length > 0) {
            teams.map(team => {
                // Removes from memberDB
                // let memberRef = database.collection('membersDatabase').doc(userUID).collection('teamList').doc(team.teamId)
                // batch.delete(memberRef)

                // Removes member from teamDB
                let oldTeamRef = database.collection('teamDatabase').doc(team.teamId)
                batch.update(oldTeamRef, { [`memberDetails.${userUID}`]: firebase.firestore.FieldValue.delete() })

                let companyTeamsRef = database.collection('companyDatabase').doc(companyId).collection('teams').doc(team.teamId);
                batch.update(companyTeamsRef, {
                    members: firebase.firestore.FieldValue.arrayRemove(userUID)
                })
            })

            return batch.commit().then(() => {
                return { success: true, data: "Member deleted successfully!" }
            }).catch(e => ({ success: false, data: e }))
        } else return { success: true, data: "Member deleted successfully!" }


    } catch (e) {
        return ({ success: false, data: e })
    }
}


export default {
    getAllTeamMembersData,
    createTeamWithMembers,
    editTeamWithMembers,
    updateMemberDetails,
    deleteTeam,
    deleteMember
}
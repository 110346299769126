import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import firebase from "../../config";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import map from "lodash/map";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";
import PulseLoader from "react-spinners/PulseLoader";

const database = firebase.firestore();
const batch = database.batch();

const Invite = (auth) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [expired, setExpired] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // Get invitation data by id
  const getInviteDetails = async () => {
    await database
      .collection("userInvites")
      .doc(id)
      .get()
      .then((response) => {
        if (response.data() !== undefined) {
          userExists(response.data());
          setData(response.data());
        } else {
          setExpired(true);
        }
      });
  };

  // Accept project Invite
  const acceptProjectInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", data?.userDetails?.email)
        .get()
        .then((collection) => {
          const auto = collection.docs.map((res) => res.id);
          const obj = {
            member_id: auto[0],
            project_id: data?.project[0]?.value,
          };
          try {
            database
              .collection("companyDatabase")
              .doc(data?.invitedBy?.companyDetails?.companyId)
              .update({
                clients: firebase.firestore.FieldValue.arrayUnion(obj),
              });
          } catch (error) {
            return error;
          }
        });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ accepted: true });
    } catch (error) {
      return error;
    }
    setLoad(false);
    getInviteDetails();
  };

  // Accept Invitation
  const acceptInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ accepted: true });
    } catch (error) {
      return error;
    }
    getInviteDetails();
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .get()
        .then(async (response) => {
          let data = response.data();
          if(data?.invitedAs === "Admin") {
            await addAdminData(data);
          }
          else{
            await addTeamToData(data);
          }
        });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.id);
          try {
            database
              .collection("membersDatabase")
              .doc(auto[0])
              .update({
                teamInvites: firebase.firestore.FieldValue.arrayRemove(id),
              });
          } catch (error) {
            addNotification({
              message: "An error occured",
              level: notificationStates.success,
            });
          }
        });
    } catch (error) {
      addNotification({
        message: "An error occured",
        level: notificationStates.success,
      });
    }
    await addNotification({
      message: "Invite accepted successfully",
      level: notificationStates.success,
    });
    setLoad(false);
    if (auth?.auth?.profile?.companyList?.length === 0) {
      localStorage.setItem("list", 1);
    }
    setInterval(window.location.assign("/inviteList"), 100000000);
  };

  // Decline Invitation
  const declineInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ declined: true });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.id);
          try {
            database
              .collection("membersDatabase")
              .doc(auto[0])
              .update({
                teamInvites: firebase.firestore.FieldValue.arrayRemove(id),
              });
          } catch (error) {
            addNotification({
              message: "An error occured",
              level: notificationStates.success,
            });
          }
        });
    } catch (error) {
      addNotification({
        message: "An error occured",
        level: notificationStates.success,
      });
    }
    addNotification({
      message: "Invite declined successfully",
      level: notificationStates.success,
    });
    setLoad(false);
    window.location = "/inviteList";
  };

  // Check if user exists
  const userExists = async (email) => {
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", email?.userDetails?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.data());

          if (auto.length !== 0) {
            if (auto[0].accountEmailid !== auth?.auth?.profile?.email) {
              dispatch({
                type: "user/LOGOUT_ACTIVE_ACCOUNT",
              });
              addNotification({
                message: "Please login to your account first",
                level: notificationStates.error,
              });
            }
          } else {
            dispatch({
              type: "user/LOGOUT_ACTIVE_ACCOUNT",
            });
            addNotification({
              message: "Please create an account first",
              level: notificationStates.error,
            });
          }
        });
    } catch (error) {
      return error;
    }
  };

  // Add Accepted teams to member database
  const addTeamToData = async (data) => {
    let memberDetails = {};
    let oldData;
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then(async (collections) => {
          const auto = collections.docs.map((res) => res.id);
          memberDetails[auto[0]] = {
            userEmailid: auth?.auth?.profile?.email,
            userName: auth?.auth?.profile?.name,
            userType: data?.invitedTo?.invitedAs,
          };

          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .get()
              .then((res) => {
                const data = res.data();
                oldData = data.memberDetails;
              });
          } catch (error) {
            return error;
          }

          // Adding member to teamDatabase
          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .update({ memberDetails: { ...oldData, ...memberDetails } });
          } catch (error) {
            console.log("Error : ", error);
          }

          let memberDBRef = database.collection("membersDatabase").doc(auto[0]);
          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .get()
              .then((response) => {
                let data = [];
                if (!response.empty) {
                  if (response.data()) {
                    const { memberDetails } = response.data();
                    let members = [];
                    if (memberDetails) {
                      members = map(memberDetails, (value, index) => {
                        return {
                          ...value,
                          userUID: index,
                          role: value.userType,
                        };
                      });
                    }
                    data = members;
                    localStorage.setItem("members", JSON.stringify(data));
                  }
                }
                
              });
          } catch (error) {
            console.log("Error : ", error);
          }
            
          //       let memberTeamRef = memberDBRef
          //         .collection("teamList")
          //         .doc(data?.invitedTo?.teamId);
          let memberCompanyRef = memberDBRef
            .collection("companyList")
            .doc(data?.invitedBy?.companyDetails?.companyId);
          let memTeamListRef = memberCompanyRef
            .collection("teamList")
            .doc(data?.invitedTo?.teamId);

          // Add data for company list
          try {
            await memberDBRef
              .collection("companyList")
              .get()
              .then(async (doc) => {
                if (doc.size > 0) {
                  await memberCompanyRef.get().then(async (doc) => {
                    if (doc.exists) {
                      batch.set(
                        memTeamListRef,
                        {
                          Role: data?.invitedTo?.invitedAs,
                          Team: data?.invitedTo?.teamName,
                          activeTeamid: memTeamListRef.id,
                          email: data?.invitedBy?.emailId,
                        },
                        { merge: true }
                      );
                      // await batch.commit();
                    } else {
                      batch.set(
                        memberCompanyRef,
                        {
                          companyId: data?.invitedBy?.companyDetails?.companyId,
                          companyName:
                            data?.invitedBy?.companyDetails?.companyName,
                          role: data?.invitedTo?.invitedAs,
                        },
                        { merge: true }
                      );
                      batch.set(
                        memTeamListRef,
                        {
                          Role: data?.invitedTo?.invitedAs,
                          Team: data?.invitedTo?.teamName,
                          activeTeamid: memTeamListRef.id,
                          email: data?.invitedBy?.emailId,
                        },
                        { merge: true }
                      );
                      // await batch.commit();
                    }
                    await batch.commit();
                    
                    let teamRefData = await memberCompanyRef
                    .collection("teamList").get()
                    .then((querySnapshot) => {
                      const tempDoc = querySnapshot.docs.map((doc) => {
                        return { ...doc.data() };
                      });
                      let x = tempDoc.filter((a) => {
                        if (a?.deleted !== true) {
                          return a;
                        }
                      });
                      console.log(x, "xxxxxxxxxxx")
                      localStorage.setItem("Team", JSON.stringify({
                        success: true,
                        data: x,
                      }));
                    });

                    return {
                      success: true,
                      data: "Team created successfully!",
                    };
                  });
                } else {
                  batch.set(
                    memberCompanyRef,
                    {
                      companyId: data?.invitedBy?.companyDetails?.companyId,
                      companyName: data?.invitedBy?.companyDetails?.companyName,
                      role: data?.invitedTo?.invitedAs,
                    },
                    { merge: true }
                  );

                  batch.set(
                    memTeamListRef,
                    {
                      Role: data?.invitedTo?.invitedAs,
                      Team: data?.invitedTo?.teamName,
                      activeTeamid: memTeamListRef.id,
                      email: data?.invitedBy?.emailId,
                    },
                    { merge: true }
                  );
                  await batch.commit();
                  let teamRefData = await memberCompanyRef
                    .collection("teamList").get()
                    .then((querySnapshot) => {
                      const tempDoc = querySnapshot.docs.map((doc) => {
                        return { ...doc.data() };
                      });
                      let x = tempDoc.filter((a) => {
                        if (a?.deleted !== true) {
                          return a;
                        }
                      });
                      console.log(x, "xxxxxxxxxxx else")
                      localStorage.setItem("Team", JSON.stringify({
                        success: true,
                        data: x,
                      }));
                    });
                  return {
                    success: true,
                    data: "Team created successfully!",
                  };
                }
              });
          } catch (error) {
            console.log("Error : ", error);
          }
          memTeamListRef.get().then((res) => {
            const teamData = res.data();
            const obj = { Team: teamData?.Team, activeTeamid: teamData?.activeTeamid }
            localStorage.setItem('teamData', JSON.stringify(obj))
          });
        });
    } catch (error) {
      return error;
    }
    // await batch.commit();
    // return {
    //   success: true,
    //   data: "Team created successfully!",
    // };
    // window.location = "/inviteList";
  };

  const addAdminData = async (data) => {
    const batch = database.batch(); // Batch operation to ensure atomicity
    const companyId = data?.invitedBy?.companyDetails?.companyId;
    const companyName = data?.invitedBy?.companyDetails?.companyName;
    let memberDetails = {};

    console.log("AddAdminData : Data", data);
  
    try {
      // 1. Get the member details from the membersDatabase
      const memberRef = database.collection("membersDatabase").where("accountEmailid", "==", data?.userDetails?.email);
      const memberDoc = await memberRef.get();
      if (!memberDoc.empty) {
        const memberData = memberDoc.docs[0].data();
        const memberId = memberDoc.docs[0].id;
        memberDetails[memberId] = {
          userEmailId: auth?.auth?.profile?.email,
          userName: auth?.auth?.profile?.name,
          userType: data?.invitedAs,
        }
      }
      console.log("AddAdminData : MemberDetails", memberDetails);
      // 2. Fetch the company document from companyDatabase
      const companyRef = database.collection("companyDatabase").doc(companyId);
      const companyDoc = await companyRef.get();
      if (!companyDoc.exists) {
        throw new Error("Company not found!");
      }
  
      const companyData = companyDoc.data();
      const teamIds = companyData?.teams; // Extracting team IDs from the 'teams' array in the company document

      console.log("AddAdminData : TeamIds", teamIds);
  
      if (!teamIds || teamIds.length === 0) {
        throw new Error("No teams found for this company!");
      }
  
      // 3. Loop through each team ID and update the respective team documents
      for (const teamId of teamIds) {
        const teamRef = database.collection("teamDatabase").doc(teamId);
        const teamDoc = await teamRef.get();
        if (teamDoc.exists) {
          const teamData = teamDoc.data();
          console.log("AddAdminData : TeamData", teamData);
          const memberDetailsUpdated = teamData.memberDetails ? { ...teamData.memberDetails, ...memberDetails } : { ...memberDetails };
          console.log("AddAdminData : MemberDetailsUpdated", memberDetailsUpdated);
  
          // Add member to the memberDetails in the team document
          await batch.update(teamRef, { memberDetails: memberDetailsUpdated });
        } else {
          console.log(`Team with ID ${teamId} not found.`);
        }
      }
  
      // 4. Update the membersDatabase to add role as "Admin"
      const memberDocRef = memberDoc.docs[0].ref; // Access the document reference directly
      const companyListRef = memberDocRef.collection("companyList").doc(companyId);  // Correctly access the subcollection
      const teamListRef = memberDocRef.collection("companyList").doc(companyId).collection("teamList");
  
      // Check if the companyList exists, if not, create it with companyId, companyName,
      //  and role
      const companyListDoc = await companyListRef.get();

      if (!companyListDoc.exists) {
        batch.set(companyListRef, {
          companyId: companyId,
          companyName: companyName,
          role: "Admin"
        });
        console.log("Created new companyList with role as Admin");
      } else {
        batch.update(companyListRef, { role: "Admin" });
        console.log("Updated companyList with role as Admin");
      }
  
      // Add all team details to the teamList
      let updatedTeamData = [];
      for (const teamId of teamIds) {
        const teamRef = database.collection("teamDatabase").doc(teamId);
        const teamDoc = await teamRef.get();
        if(teamDoc.exists) {
          const teamData = teamDoc.data();
          batch.set(teamListRef.doc(teamId), { 
            Role: "Admin", 
            Team : teamData.teamName, 
            activeTeamid : teamId, email: 
            data.userDetails.email 
          }, { merge: true });

          // Prepare updated team data for localStorage
          updatedTeamData.push({
            Role: "Admin",
            Team: teamData.teamName,
            activeTeamid: teamId,
            email: data.userDetails.email
        });
        }
      }

      // 5. Update the company admins array by adding this user's email
      const admins = companyData?.admins || [];  // Existing admins or empty array
      if (!admins.includes(memberDoc.docs[0].id)) {
        admins.push(memberDoc.docs[0].id);  // Add user's email to the admins array
        batch.update(companyRef, { admins: admins });
        console.log("Added user email to admins array in company database");
      }
  
      // 6. Commit the batch operation to Firestore
      await batch.commit();
      console.log("Admin added successfully across all teams!");

      // 7. Store the updated team data in localStorage
      localStorage.setItem("Team", JSON.stringify({
        success: true,
        data: updatedTeamData,
      }));
  
      // Return success
      return { success: true, message: "Admin added successfully!" };
  
    } catch (error) {
      console.log("Error adding admin:", error);
      return { success: false, message: error.message };
    }
  };
  

  useEffect(() => {
    getInviteDetails();
  }, []);

  return (
    <div style={{ paddingTop: "5rem!important" }}>
      {/* {userData !== [] ? ( */}
      {load ? (
        <Container
          className="pt-md-6 d-flex flex-column align-items-center justify-center"
          fluid
        >
          <PulseLoader color={"#02a17c"} />
        </Container>
      ) : (
        <Container
          className="pt-md-6 d-flex flex-column align-items-center justify-center"
          fluid
        >
          {data?.accepted === false && (
            <>
              {data?.invitedAs !== "Client" ? (
                <>
                  <p className="">
                    Hi, you have been invited to join team{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedTo?.teamName}
                    </span>{" "}
                    by company{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedBy?.companyDetails?.companyName}
                    </span>{" "}
                    as a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedAs}
                    </span>
                    .
                  </p>
                  {/* <Button onClick={userExists(data)}>Click</Button> */}
                  <div className="d-flex">
                    <Button color="primary" onClick={acceptInvite}>
                      Accept
                    </Button>
                    <Button
                      className=""
                      onClick={() => {
                        declineInvite();
                      }}
                    >
                      Decline
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="">
                    Hi, you have been invited to join project{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.project[0]?.label}
                    </span>{" "}
                    by company{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedBy?.companyDetails?.companyName}
                    </span>{" "}
                    as a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedAs}
                    </span>
                    .
                  </p>
                  {/* <Button onClick={userExists(data)}>Click</Button> */}
                  <div className="d-flex">
                    <Button color="primary" onClick={acceptProjectInvite}>
                      Accept
                    </Button>
                    <Button
                      className=""
                      onClick={() => {
                        declineInvite();
                      }}
                    >
                      Decline
                    </Button>
                  </div>
                </>
              )}
            </>
          )}{" "}
          {data?.declined && (
            <p>
              Invitation declined or expired. Please contact team admin to send
              invitaion again
            </p>
          )}
          {data?.accepted && <p>Invitation accepted successfully</p>}
        </Container>
      )}
    </div>
  );
};

export default connect(({ auth }) => ({ auth }))(withRouter(Invite));

import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Link } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorRenderer } from "../../../utils/utility";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import teamService from "../../../services/manageTeams";

import {
  addNotification,
  notificationStates,
} from "../../../components/Notification";

export default function TeamDialogForm({
  toggleTeamDialog,
  teamDialogForm,
  formType,
  teamDetails,
}) {
  /**
   * Declarations
   */
  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    control,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { teamName: "", name: "" } });
  const dispatch = useDispatch();

  const authState = useSelector((state) => ({ ...state.auth }));
  const teamState = useSelector((state) => ({ ...state.team }));
  let userProfile = authState?.profile;
  let authUserRole = userProfile?.role;

  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let { success, pending, failure, successPayload, failurePayload } =
    membersState;
  let membersOptions = successPayload
    ? (successPayload?.data ?? []).filter(
        (mem) => mem.userUID !== userProfile?.id
      )
    : [];

  function getMemberOptions() {
    let ops = [];
    if (successPayload) {
      (successPayload?.data ?? [])
        .filter((mem) => mem.userUID !== userProfile?.id)
        .map((mb) => {
          let obj = { ...mb };
          if (authUserRole == "Manager") {
            if (["Head", "Manager", "Admin"].includes(mb?.role)) {
              obj["disabled"] = true;
            }
          }
          if (authUserRole == "Head") {
            if (["Admin"].includes(mb?.role)) {
              obj["disabled"] = true;
            }
          }
          ops.push(obj);
        });
    }
    return [...ops];
  }

  const [selectedMembers, setSelectedMembers] = useState();
  const [teamName, setTeamName] = useState("");
  let [requesting, setRequesting] = useState(false);

  useEffect(() => {
    if (formType == "edit" && teamDetails) {
      register("teamName");
      setTeamName(teamDetails.teamName);
      setValue("teamName", teamDetails.teamName);
      const selectedMembs = teamDetails.members
        .map((tm) => tm.userUID !== userProfile?.id && tm.userUID)
        .filter(Boolean);
      setSelectedMembers(selectedMembs);
    } else {
      resettingForm();
    }
  }, [formType, teamDetails]);

  const handleTeamFormSubmit = async (data) => {
    try {
      setRequesting(true);
      let finalizeMs = (membersOptions ?? []).filter((itm) =>
        selectedMembers.includes(itm.userUID)
      );
      let reqPayload = {
        teamName: data.teamName,
        createdBy: {
          role: userProfile.role,
          email: userProfile.email,
          userId: userProfile.id,
          name: userProfile.name,
        },
        ownedBy: userProfile?.activeCompany?.companyId,
        members: [...finalizeMs],
      };

      if (formType == "add") {
        let response = await teamService.createTeamWithMembers({
          ...reqPayload,
        });

        handleResponse(response);
      } else {
        // ** Omit the owners id from removal
        let preMembers = teamDetails.members
          .filter((tm) => tm.userUID != userProfile?.id)
          .map((tm) => tm.userUID);
        reqPayload["teamId"] = teamDetails.team;
        reqPayload["shallRemove"] = preMembers.filter(
          (pm) => !selectedMembers.includes(pm)
        );
        let response = await teamService.editTeamWithMembers({ ...reqPayload });
        handleResponse(response);
      }
    } catch (e) {
      setRequesting(false);
    }
  };

  function handleResponse(response) {
    if (response.success) {
      dispatch({
        type: "team/GET_USER_ACTIVE_TEAM",
        payload: {
          userId: userProfile.id,
          companyId: userProfile?.activeCompany?.companyId,
          role: userProfile?.role,
          activeTeamid: teamState?.active?.activeTeamid,
          resetTeamsData: true,
        },
      });
      dispatch({
        type: "team/SWITCH_COMPANY_ACTIVE_TEAM",
        payload: {
          companyId: userProfile?.activeCompany?.companyId,
          userId: authState?.profile?.id,
        },
      });
      toggleTeamDialog(false);
      resettingForm();
      addNotification({
        message: response.data,
        level: notificationStates.success,
      });
    } else {
      addNotification({
        message: response.data,
        level: notificationStates.error,
      });
    }
  }

  useEffect(() => {
    return () => {
      resettingForm();
    };
  }, []);

  function resettingForm() {
    setRequesting(false);
    reset();
    setSelectedMembers([]);
    setTeamName("");
  }

  return (
    <Dialog
      blockScroll
      draggable={false}
      header={formType === "add" ? "Add a new team" : "Edit team"}
      contentClassName="rounded-bottom"
      visible={teamDialogForm}
      onHide={() => toggleTeamDialog(false)}
      style={{ width: "360px" }}
    >
      <div>
        <form onSubmit={handleSubmit(handleTeamFormSubmit)}>
          <div className="p-field my-3">
            <Controller
              name="teamName"
              rules={{ required: "Team name is required" }}
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id="teamName"
                  {...field}
                  autoFocus
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                    setValue("teamName", e.target.value);
                    clearErrors();
                  }}
                  placeholder="Name"
                  className={classNames(
                    { "p-invalid": errors?.teamName },
                    "p-inputtext-sm w-100"
                  )}
                />
              )}
            />
            {errorRenderer(errors, "teamName")}
          </div>
          {formType === "add" ? (
            <div className="w-100">
              <Button
                type="submit"
                loading={requesting}
                className="w-100 p-button-sm"
                label="Add team"
                autoFocus
              />
            </div>
          ) : (
            <div className="w-100 row m-0">
              <div className="col-6 pl-0 ">
                <Button
                  type="button"
                  label="Cancel"
                  onClick={() => toggleTeamDialog(false)}
                  className="p-button-outlined p-button-secondary p-button-sm w-100"
                />
              </div>
              <div className="col-6 pr-0">
                <Button
                  type="submit"
                  loading={requesting}
                  label="Save"
                  className="p-button-sm p-button-primary w-100"
                  autoFocus
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );
}

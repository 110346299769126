const actions = {
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  CREATE_POC: "CREATE_POC",
  GET_POC_DATA: "GET_POC_DATA",
  SET_POC_DATA: "SET_POC_DATA",
  SET_LOADING: "SET_LOADING",
  GET_APPLICATION_INFO: "GET_APPLICATION_INFO",
  SET_APPLICATION_INFO: "SET_APPLICATION_INFO"
};

export default actions;

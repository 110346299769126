import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  Table,
  CardTitle
} from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import fileActions from "../../actions/File";
import ImageExpander from "../../components/ExpandingImage/expandingImage";
import BlockUi from "react-block-ui";
import DatePicker from "react-datepicker";
import { convertMinutes, getFormattedDate, getTimeByDateString, getAppNameByAppPath } from "../../utils/utility";

const ApplicationDashboardInternal = () => {
  const {
    // teamId, userId,
    pocData,
  } = useSelector((state) => ({
    // teamId: state.team.selectedTeamID,
    // userId: state.auth.profile.id,
    pocData: state.poc,
  }));

  const { calculatedTimelog , applicationInfo = {}} = pocData ?? {};
  const {
    totalTimeTracked = 0,
    appTimeTrackMap = {},
    screenShots = [],
    punchOut = "N/A",
    punchIn = "N/A",
    breakTime= 0,
    idleTime = 0,
  } = calculatedTimelog ?? {};

  // hardcode the teamId and userId for testing

  const teamId = "tDfST6zR8QpV7KC8shFP";
  const userId = "axxJPPwBWmgEr4TbpOGLyjyyVzx1";

  const defaultUserToken = "db0f20228992996a625977cf759fa30bd65ecdf7f4bc3bba55bf99f2f8514cf2";
  const userTokenOptions = [
    { label: "Ayush", value: "983168529248a742d16af9251787d522f2788fcfbf84a7a8ff75a47a76f9c449" },
    { label: "Geet", value: "db0f20228992996a625977cf759fa30bd65ecdf7f4bc3bba55bf99f2f8514cf2" },
    { label: "Dhananjay", value: "21c0097ecfbea51b2b840fc5fde45063abfe522cd98870361095fa0db9129dca" },
    { label: "My Token", value: "eT8rYpF6WmV5JxK1LzS4Xe" },
    { label: "Your Token", value: "mNqJkT7pZxA1RKl4FqM9Dq" },
  ];

  const [userToken, setUserToken] = useState(defaultUserToken);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch();

  const getHoursMinuteByMin = (timeString) => {
    let duration = timeString,
      time = Number(timeString);
    if (timeString.includes("min")) {
      const timeSplit = timeString.split("min");
      if (timeSplit.length > 1) {
        time = Number(timeSplit[0].trim());
      }
    }
    const { hours, minutes, seconds } = convertMinutes(time);
    duration = `${hours}hrs ${minutes}mins ${seconds}secs`;
    return duration;
  };

  useEffect(() => {
    if (userId && teamId && userToken && selectedDate) {
      dispatch({
        type: fileActions.GET_POC_DATA,
        payload: {
          userId,
          teamId,
          userToken,
          formattedDate: getFormattedDate(selectedDate),
          companyId: "wal3xRi8taICeQZN235Z"
        },
      });
    }
  }, [teamId, userId, userToken, selectedDate]);

  useEffect(() => {
    dispatch({ type: fileActions.GET_APPLICATION_INFO });
  }, []);

  return (
    <Container className="pt-md-2" fluid>
      {pocData.loading ? (
        <div className="loader-container">
          <BlockUi tag="div" blocking message="Loading, please wait" />
        </div>
      ) : (
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="m-0 justify-content-between">
                  <Col xs="12" className="m-0 p-0">
                    <div>
                      <h3>Application Time Tracked Dashboard - Internal</h3>
                    </div>
                    <div className="row m-0">
                      <Dropdown
                        value={userToken}
                        options={userTokenOptions}
                        onChange={(e) => {
                          setUserToken(e.target.value);
                        }}
                        className="row align-items-center m-0"
                      />
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CardTitle>
                  Total Time Tracked:{" "}
                  {getHoursMinuteByMin(
                    String(totalTimeTracked)
                  )}
                </CardTitle>
                <CardTitle>
                  Idle Time:{" "}
                  {getHoursMinuteByMin(
                    String(idleTime)
                  )}
                </CardTitle>
                {/* <CardTitle>
                  Total Break Time: 
                  {getHoursMinuteByMin(
                    String(breakTime)
                  )}
                </CardTitle> */}
                <CardTitle>
                  Punch In:{" "}{punchIn}
                </CardTitle>
                <CardTitle>
                  Punch Out:{" "}{punchOut}
                </CardTitle>
              </CardBody>
              <CardBody>
                <Table hover responsive bordered size="sm" striped>
                  <thead>
                    <tr>
                      <th>Application Name</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(appTimeTrackMap)
                    ?.map(([app, time], index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{getAppNameByAppPath(app,applicationInfo)}</th>
                          <th scope="row">
                            {time ? getHoursMinuteByMin(String(time)) : 0}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
              <CardBody className="row flex-wrap">
              {screenShots?.reverse().map((urlObj, idx) => {
                  const [date, url] = Object.entries(urlObj)[0];
                  return (
                      <ImageExpander
                        src={url}
                        alt="screenshot"
                        key={`screenshot-${idx}`}
                        text={getTimeByDateString(date)}
                      />
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ApplicationDashboardInternal;

import moment from "moment";
import firebase from "../config";
import map from "lodash/map";

const database = firebase.firestore();
// const runTransaction = firebase.firestore();

function getTeamData(teamId) {
  try {
    return database
      .collection("teamDatabase")
      .doc(teamId)
      .get()
      .then((response) => {
        let data;
        if (!response.empty) {
          const docData = response.data();
          data = docData;
        }
        return {
          success: true,
          data: data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function get7DaysTimes(teamId, day) {
  try {
    return database
      .collection("teamData")
      .doc(teamId)
      .collection("daysData")
      .get()
      .then((response) => {
        let data;
        if (!response.empty) {
          const docData = response.data();
          data = docData;
        }
        return {
          success: true,
          data: data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getTimes(teamId, day) {
  try {
    return database
      .collection("teamData")
      .doc(teamId)
      .collection("attendanceTable")
      .doc(day)
      .get()
      .then((response) => {
        let data;
        if (response.exists) {
          const docData = response.data();
          data = docData;
          return {
            success: true,
            data: data,
          };
        } else {
          return {
            success: false,
            data: data,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getTopData(teamId, day) {
  try {
    return database
      .collection("teamData")
      .doc(teamId)
      .collection("membersData")
      .doc(day)
      .get()
      .then((response) => {
        let data;
        if (response.exists) {
          const docData = response.data();
          data = docData;
          return {
            success: true,
            data: data,
          };
        } else {
          return {
            success: false,
            data: data,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getWeekData(teamId, week) {
  let year = moment().isoWeekYear();
  week = `${week}-${year}`;
  console.log("weekkkkkkkyeayyyyyyy", week);
  try {
    return database
      .collection("teamData")
      .doc(teamId)
      .collection("weeksData")
      .doc(week)
      .get()
      .then((response) => {
        let data;
        if (response.exists) {
          const docData = response.data();
          data = docData;
          return {
            success: true,
            data: data,
          };
        } else {
          return {
            success: false,
            data: data,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getActiveTeamID(memberID) {
  try {
    return database
      .collection("membersDatabase")
      .doc(memberID)
      .get()
      .then((response) => {
        let data;
        if (!response.empty) {
          const docData = response.data();
          data = docData;
        }
        return {
          success: true,
          data: data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

// async function addTaskList(data)
// async function setActiveTeamId(activeTeamId, activeUserID) {
//   const snapshot = database.collection("membersDatabase").doc(activeUserID);
//   const doc = await snapshot.get();

//   const activeTeam = doc.data();
//   if (activeTeam.activeTeamid) {
//     return database
//       .collection("membersDatabase")
//       .doc(activeUserID)
//       .update({
//         activeTeamid: activeTeamId,
//       })
//       .then(() => {
//         return {
//           success: true,
//         };
//       });
//   } else {
//     return database
//       .collection("membersDatabase")
//       .doc(activeUserID)
//       .set(
//         {
//           activeTeamid: activeTeamId,
//         },
//         { merge: true }
//       )
//       .then(() => {
//         return {
//           success: true,
//         };
//       });
//   }
// }

function setThreshold(companyId, threshold) {
  try {
    return database
      .collection("companyDatabase")
      .doc(companyId)
      .set(
        {
          dailyTimelogThreshold: threshold,
        },
        { merge: true }
      )
      .then(() => {
        return {
          success: true,
        };
      });
  } catch (error) {
    console.log(error);
  }
}
function getThreshold(companyId) {
  if (companyId === "") {
    return {
      success: true,
      data: 0,
    };
  }
  try {
    return database
      .collection("companyDatabase")
      .doc(companyId)
      .get()
      .then((response) => {
        let data = {};
        if (!response.empty) {
          data = response.data();
        }
        return {
          success: true,
          data: data.dailyTimelogThreshold,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getTeamNames(userId, companyId) {
  try {
    return database
      .collection("membersDatabase")
      .doc(userId)
      .collection("companyList")
      .doc(companyId)
      .collection("teamList")
      .get()
      .then((querySnapshot) => {
        const tempDoc = querySnapshot.docs.map((doc) => {
          return { ...doc.data() };
        });
        let x = tempDoc.filter((a) => {
          if (a?.deleted !== true) {
            return a;
          }
        });
        return {
          success: true,
          data: x,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getActiveTeam(memberID, activeTeamID, companyId) {
  try {
    return database
      .collection("membersDatabase")
      .doc(memberID)
      .collection("companyList")
      .doc(companyId)
      .collection("teamList")
      .doc(activeTeamID)
      .get()
      .then((response) => {
        let data = {};
        if (!response.empty) {
          data = response.data();
        }
        return {
          success: true,
          data: data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.log(error);
  }
}

function getTeamMembers(teamID) {
  return database
    .collection("teamDatabase")
    .doc(teamID)
    .get()
    .then((response) => {
      let data = [];
      if (!response.empty) {
        if (response.data()) {
          const { memberDetails } = response.data();
          let members = [];
          if (memberDetails) {
            members = map(memberDetails, (value, index) => {
              return {
                ...value,
                userUID: index,
                role: value.userType,
              };
            });
          }
          data = members;
        }
      }
      return {
        success: true,
        data: data,
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

function getManagerTeamCollection(teamID, selectedID) {
  return database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("members")
    .doc(selectedID)
    .collection("days")
    .get()
    .then((querySnapshot) => {
      let data = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          data.push({
            date: doc.id,
            ...doc.data(),
          });
        });
      }
      return {
        success: true,
        data: data,
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}
function getUserTeamCollection(teamID, selectedDate, profileID) {
  return database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("days")
    .doc(selectedDate)
    .collection("members")
    .doc(profileID)
    .get()
    .then((response) => {
      let data = [];
      if (!response.empty) {
        data.push({
          id: response.id,
          ...response.data(),
        });
      }
      return {
        success: true,
        data: data,
      };
    })
    .catch(() => {
      return {
        success: false,
        data: [],
      };
    });
}

async function getTeamsData(payload) {
  const { companyId, userId, role } = payload;
  if (companyId) {
    return await database
      .collection("teamDatabase")
      .where("ownedBy", "==", companyId)
      .get()
      .then((res) => {
        let data = [];
        if (!res.empty) {
          res.forEach((t) => {
            const cTeam = t.data();
            if (cTeam) {
              // returns all teams of selected company
              if (role === "Admin") {
                data.push({
                  docId: t.id,
                  Role: role,
                  Team: cTeam.teamName,
                });
              } else {
                // returns only teams that user is member of
                const { memberDetails } = cTeam;
                if (memberDetails) {
                  map(memberDetails, (value, memId) => {
                    if (memId == userId) {
                      data.push({
                        docId: t.id,
                        Role: role,
                        Team: cTeam.teamName,
                      });
                    }
                  });
                }
              }
            }
          });
        }
        return {
          success: true,
          data: data,
        };
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  }
}

async function getMemberTeamCollection(payload) {
  const { companyId, userId, role } = payload;

  // if (role == 'Member') {
  return await getTeamsData(payload);
  // } else {
  //   return database
  //     .collection("membersDatabase")
  //     .doc(userId)
  //     .collection("teamList")
  //     .get()
  //     .then((response) => {
  //       let data = [];
  //       if (!response.empty) {
  //         response.forEach((doc) => {
  //           data.push({
  //             docId: doc.id,
  //             ...doc.data(),
  //           });
  //         });
  //       }
  //       return {
  //         success: true,
  //         data: data,
  //       };
  //     })
  //     .catch((error) => {
  //       return {
  //         success: false,
  //         data: error,
  //       };
  //     });
  // }
}

function getMembersTimeLog(teamID, userID, startDate, endDate) {
  return database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("members")
    .doc(userID)
    .collection("days")
    .get()
    .then((data) => {
      const days = [];

      data.forEach(function (doc) {
        const day = doc.id.replaceAll(":", "-");
        if (
          moment(day, "DD-MM-YYYY").isBetween(
            moment(startDate, "DD-MM-YYYY"),
            moment(endDate, "DD-MM-YYYY"),
            "day",
            "[]"
          )
        ) {
          days.push({
            day,
            punchData: doc.data()?.punchinout,
            timeLog: doc.data()?.timelog,
            leave: doc.data()?.leave,
          });
        }
      });
      return {
        success: true,
        data: days,
      };
    })
    .catch((err) => {
      return {
        success: false,
        data: err,
      };
    });
}

async function getAllTeamTimeData(teamId, day) {
  return database
    .collection("teamData")
    .doc(teamId)
    .collection("daysData")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getDateRangeTimeData(start, end, teamId) {
  console.log(start, end, teamId, "getDateRangeTimeData");
  try {
    const url = `https://us-central1-workfh-b2cee.cloudfunctions.net/dateRangeForTeam`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=utf-8");
    headers.append("Access-Control-Allow-Origin", "*");

    return await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({
        start,
        end,
        teamId,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        console.log("data in getDateRangeTimeData", res.result);
        let data;
        if (res.success) {
          data = res.result;
          console.log("data in success", data);
          return {
            success: true,
            data: res.result,
          };
        } else {
          return {
            success: false,
            data: res.message,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (err) {
    console.log(err);
  }
}

async function getAllTeamWeekTimeData(teamId, day) {
  return database
    .collection("teamData")
    .doc(teamId)
    .collection("lastWeekData")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getAllTeamMonthTimeData(teamId, day) {
  return database
    .collection("teamData")
    .doc(teamId)
    .collection("lastMonthData")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getAllTeamTimeLog(teamID) {
  const snapshot = await database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("members")
    .get();
  let data = [];
  if (!snapshot.empty) {
    const promiseAr = [];
    // Iterate through each member and fetch their respective time logs
    snapshot.forEach((doc) => {
      // `doc.id` is the memberId
      promiseAr.push(
        database
          .collection("teamDatabase")
          .doc(teamID)
          .collection("members")
          .doc(doc.id) // store the member id
          .collection("days")
          .get()
          .then((res) => ({ memberId: doc.id, res })) // Attach memberId to the response
      );
    });
    const timeLogs = await Promise.all(promiseAr);
    timeLogs.forEach(({ memberId, res }) => {
      res.forEach((d) => {
        data.push({
          ...d.data(),
          date: d.id, // Assuming the `d.id` is the date
          memberId, // Add memberId to each time log entry
        });
      });
    });
    return {
      success: true,
      data, // This now contains both memberId and time log data
    };
  } else {
    return { success: true, data: [] };
  }
}

async function addProject(payload) {
  const data = {
    project_name: payload.project,
    createdBy: payload.createdBy,
  };
  return database
    .collection("companyDatabase")
    .doc(payload.companyId)
    .collection("projects")
    .doc()
    .set(data, {
      merge: true,
    });
}

async function getProjects(companyId) {
  const snapshot = await database
    .collection("companyDatabase")
    .doc(companyId.companyId)
    .collection("projects")
    .get()
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  const data = snapshot.docs.map((doc) => {
    const docData = doc.data();
    return {
      data: docData,
      id: doc.id,
    };
  });
  return {
    success: true,
    data: data,
  };
}

// async function getProjectId(memberId, companyId) {
//   //gets a snapshot of the projects of collection projectList
//   const snapshot = await database
//     .collection("membersDatabase")
//     .doc(memberId)
//     .collection("companyList")
//     .doc(companyId)
//     .collection("projectList")
//     .get()
//     .catch((error) => {
//       console.log("Error getting documents: ", error);
//     });

//   // Get id's of the projects
//   const Ids = snapshot.docs?.map(doc => doc.id);

//   // Get names of the projects
//   const Names = snapshot.docs?.map(doc => doc.data());

//   const NameId = Ids.map((id, index) => {
//     return {
//       id,
//       name: Names[index],
//     };
//   })
//   return {
//     success: true,
//     data: NameId
//   }
// }
async function getProjectId(memberId, companyId) {
  //gets a snapshot of the projects from the companyDatabase
  const snapshot = await database
    .collection("companyDatabase")
    .doc(companyId)
    .collection("projects")
    .get()
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  // Get id's of the projects
  const Ids = snapshot.docs?.map((doc) => doc.data().p_id);

  return {
    success: true,
    data: Ids,
  };
}

async function getTopProjects(projectId, rawData, arr) {
  //gets a snapshot of the projects from the companyDatabase
  const data = await database
    .collection("companyDatabase")
    .doc(localStorage.getItem("cid"))
    .collection("projects")
    .doc(projectId)
    .get();
  const dataRaw = data.data();
  const objIndex = rawData.findIndex(
    (obj) => obj.projectName == dataRaw.project_name
  );
  if (objIndex == -1) {
    rawData.push({
      projectName: dataRaw.project_name,
      time:
        parseInt(`${arr?.taskMinutes}`) < 10
          ? parseFloat(`${arr.taskHours}.0${arr.taskMinutes}`)
          : parseFloat(`${arr.taskHours}.${arr.taskMinutes}`),
      minuites: parseInt(`${arr?.taskMinutes}`),
      hours: parseInt(`${arr?.taskHours}`),
      color: "#" + Math.floor(Math.random() * 16777215).toString(16),
    });
  } else {
    rawData[objIndex].time +=
      parseInt(`${arr?.taskMinutes}`) < 10
        ? parseFloat(`${arr.taskHours}.0${arr.taskMinutes}`)
        : parseFloat(`${arr.taskHours}.${arr.taskMinutes}`);
    rawData[objIndex].minuites += parseInt(`${arr.taskMinutes}`);
    rawData[objIndex].hours += parseInt(`${arr.taskHours}`);
    if (rawData[objIndex].minuites > 59) {
      rawData[objIndex].minuites = 0;
      ++rawData[objIndex].hours; // rawData[objIndex].hours += 1;
    }
  }
}

async function getTeamsId(companyId) {
  //gets a snapshot of the teams from the companyDatabase
  const snapshot = await database
    .collection("companyDatabase")
    .doc(companyId)
    .collection("teams")
    .get()
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  // Get id's of the teams

  const Ids = [];
  // eslint-disable-next-line no-unused-vars
  let temp = snapshot.docs?.forEach((doc) => Ids.push(doc.data()));

  return {
    success: true,
    data: Ids,
  };
}

// async function getTeamsId(memberId, companyId) {
//   //gets a snapshot of the teams of collection teamList
//   const snapshot = await database
//     .collection("membersDatabase")
//     .doc(memberId)
//     .collection("companyList")
//     .doc(companyId)
//     .collection("teamList")
//     .get()
//     .catch((error) => {
//       console.log("Error getting documents: ", error);
//     });

//   // Get id's of the teams
//   const Ids = snapshot.docs?.map(doc => doc.id);

//   // Get names of the teams
//   const Names = snapshot.docs?.map(doc => doc.data().Team);

//   const NameId = Ids.map((id, index) => {
//     return {
//       id,
//       name: Names[index],
//     };
//   })
//   return {
//     success: true,
//     data: NameId,
//   };
// }

async function getCompaniesId(memberId) {
  //gets a snapshot of the companies of collection teamList
  try {
    const snapshot = await database
      .collection("membersDatabase")
      .doc(memberId)
      .collection("companyList")
      .get()
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    // Get id's of the companies
    const Ids = snapshot.docs?.map((doc) => doc.id);

    // Get names of the companies
    const Names = snapshot.docs?.map((doc) => doc.data().companyName);

    const NameId = Ids.map((id, index) => {
      return {
        id,
        name: Names[index],
      };
    });
    return {
      success: true,
      data: NameId,
    };
  } catch (error) {
    console.log(error);
  }
}

async function updateProject({ team, projectId, title, companyId }) {
  const snapshot = await database
    .collection("companyDatabase")
    .doc(companyId)
    .collection("projects")
    .doc(projectId)
    .update({ project_name: title })
    .catch((err) => {
      console.log("err", err);
    });
  return snapshot;
}

async function addTaskList(data) {
  const taskRef = database.collection("taskDatabase").doc();
  return database
    .runTransaction(async (transaction) => {
      const cityRef = database
        .collection("companyDatabase")
        .doc(data.companyId)
        .collection("projects")
        .doc(data.value.team);
      const doc = await transaction.get(cityRef);

      const project = doc.data();
      const arr = [];
      arr.push(taskRef.id);

      if (project.taskList) {
        project.taskList.map((task) => arr.push(task));
        const newA = project.taskList.filter(
          (task) => task.toLowerCase() === data.value.task.toLowerCase()
        );
        if (newA.length === 0) {
          const cityRef = database
            .collection("companyDatabase")
            .doc(data.companyId)
            .collection("projects")
            .doc(data.value.team);
          await transaction.update(cityRef, { taskList: arr });
        }
      } else {
        const cityRef = database
          .collection("companyDatabase")
          .doc(data.companyId)
          .collection("projects")
          .doc(data.value.team);
        await transaction.update(cityRef, { taskList: [taskRef.id] });
      }
      transaction.set(taskRef, {
        Title: data?.value?.task,
        Description: data?.value?.summery,
        Priority: data?.value?.priority,
        Assignee: data?.value?.assignee,
        Status: data?.value?.status,
        ProjectID: data?.value?.team,
      });
    })
    .then((data) => {
      return { message: "Task added successfully", data: data };
    })
    .catch((err) => {
      // This will be an "population is too big" error.
      console.error(err);
    });
}

async function getTaskList({ teamId, activeUserId }) {
  if (activeUserId) {
    const data = await database
      .collection("teamDatabase")
      .doc(teamId)
      .collection("members")
      .doc(activeUserId)
      .get();

    return {
      success: true,
      data: [data.data().inProgressTaskList],
    };
  }
  return database
    .collection("teamDatabase")
    .doc(teamId)
    .collection("members")
    .get()
    .then(async (res) => {
      const promiseAr = [];
      res.forEach((doc) => {
        promiseAr.push(
          database
            .collection("teamDatabase")
            .doc(teamId)
            .collection("members")
            .doc(doc.id)
            .get()
        );
      });
      const data = [];
      const r = await Promise.all(promiseAr);
      r.forEach((res) => {
        data.push(res.data().inProgressTaskList);
      });

      return {
        success: true,
        data,
      };
    })
    .catch((err) => console.log(err));
}

async function deleteProject({ teamId, projectId, title, companyId }) {
  return database
    .collection("companyDatabase")
    .doc(companyId.companyId)
    .collection("projects")
    .doc(projectId)
    .update({ deleted: true })
    .catch((err) => {
      console.log("err", err);
    });
}

async function getAllMembers(emails) {
  let count = 0;
  let startIndex = 0;
  let endIndex = 10;
  let emsLength = emails.length;
  let existed = [];
  do {
    let emsData = emails.slice(startIndex, endIndex);
    startIndex = endIndex;
    endIndex = endIndex + 10;
    let querySnapshot = await database
      .collection("membersDatabase")
      .where("accountEmailid", "in", emsData)
      .get();
    if (querySnapshot) {
      if (!querySnapshot.empty) {
        let a = querySnapshot.docs.map((doc) => doc.data());
        existed = [...existed, ...a];
      }
    }
  } while (endIndex < emsLength + 1);
  return existed;
}

/**
 * Get all team members data team-wise
 * @param {*} teams
 * @returns
 */
async function getAllTeamMembers({ teams, authUser, role }) {
  try {
    let tempTeams = teams;
    let finalTeamData = [];
    let count = 0;

    do {
      let teamId = tempTeams[count].value;
      let teamName = tempTeams[count].label;
      let { success, data } = await getTeamMembers(teamId);
      if (success) {
        // Returns only member details
        // if (role == 'Member') {
        //   data = data.filter(mem => mem.userUID == authUser);
        // }
        let x = data.filter((a) => {
          if (a?.deleted !== true) {
            return a;
          }
        });
        finalTeamData.push({
          team: teamId,
          teamName: teamName,
          members: x,
          noOfMembers: data.length,
        });
      }

      count = count + 1;
    } while (count < tempTeams.length);

    return { success: true, data: [...finalTeamData] };
  } catch (e) {
    return { success: false, data: e };
  }
}

/**
 * Gets Eligible users from existing users
 * @param {*} existedUsers
 * @param {*} teamsData
 * @param {*} teams
 * @returns
 */
function getExistedEligibleUsers(existedUsers, teamsData, teams, isAdmin, userType) {
  if (existedUsers && teamsData) {
    let existedEmails = existedUsers.map((usr) => usr.accountEmailid);
    let existedEligibleUsersData = [];

    existedEmails.forEach((existedEmail) => {
      let eligibleFor = [];

      teamsData.forEach((team) => {
        const memberEntry = team.members.find(
          (member) => member.userEmailid === existedEmail
        );

        if (!memberEntry) {
          // Not in the team at all, eligible to be added
          eligibleFor.push({ teamId: team.team, teamName: team.teamName });
        } else if (memberEntry.role !== userType) {
          // In the team, but with a different role — allow re-invite with new role
          eligibleFor.push({ teamId: team.team, teamName: team.teamName });
        }
        // else: Already in the team with same role, skip
      });

      if (eligibleFor.length > 0) {
        existedEligibleUsersData.push({
          email: existedEmail,
          eligibleFor,
          registered: true,
        });
      }
    });

    return existedEligibleUsersData;
  }
}

/**
 * Returns finalize data to send invites to the users
 * @param {*} existedUsers
 * @param {*} emails
 * @param {*} teamsIDs
 * @param {*} existedEligibleUsersData
 * @param {*} sender
 * @param {*} userType
 * @param {*} projects
 * @returns
 */

function getFinalizedProjectInvitation(
  existedUsers,
  allEmails,
  projects,
  sender,
  userType
) {
  let finalizeInvitationData = [];
  let inviteObj = {};
  let existedEmails = existedUsers.map((usr) => usr.accountEmailid);
  let nonExistedEmails = allEmails.filter(
    (email) => !existedEmails.includes(email)
  );
  inviteObj = {
    invitedBy: {
      emailId: sender.email,
      fullName: sender.name,
      companyDetails: { ...sender.companyDetails },
    },
    project: projects,
    invitedAs: userType,
    userDetails: {
      email: existedEmails[0],
    },
    accepted: false,
  };

  finalizeInvitationData.push(inviteObj);
  return finalizeInvitationData;
}

function getFinalizeInvitationData(
  existedUsers,
  emails,
  teamsIDs,
  existedEligibleUsersData,
  sender,
  userType
) {
  let finalizeInvitationData = [];
  // Map through all teams and send invites
  let existedEmails = existedUsers.map((usr) => usr.accountEmailid);
  let nonExistedEmails = emails.filter(
    (email) => !existedEmails.includes(email)
  );

  let nonExistEligibleUsers = nonExistedEmails.map((nEEmail) => ({
    email: nEEmail,
    eligibleFor: [...teamsIDs],
    registered: false,
  }));

  let allEligibleUsersData = [
    ...existedEligibleUsersData,
    ...nonExistEligibleUsers,
  ];

  // Map all data to eligible teams & refactor data

  allEligibleUsersData.map((eligibleUser) => {
    let eligibleTeams = eligibleUser.eligibleFor;
    let inviteObj = {};

    eligibleTeams.map((eligibleTeam) => {
      inviteObj = {
        invitedBy: {
          emailId: sender.email,
          fullName: sender.name,
          companyDetails: { ...sender.companyDetails },
        },
        invitedTo: {
          teamId: eligibleTeam.teamId,
          teamName: eligibleTeam.teamName,
          invitedAs: userType,
        },
        invitedAs: userType,
        userDetails: {
          email: eligibleUser.email,
          registered: eligibleUser.registered,
        },
        accepted: false,
      };

      finalizeInvitationData.push(inviteObj);
    });
  });

  return finalizeInvitationData;
}

async function addInvitesToDatabase(invitationData, userType) {
  const statusMessages = [];
  try {
    console.log("Invitation Data", invitationData);

    for (const invite of invitationData) {
      const email = invite?.userDetails?.email;
      const newRole = invite?.invitedAs;
      const companyId = invite?.invitedBy?.companyDetails?.companyId;

      if (!email || !newRole || !companyId) {
        console.warn("Missing essential data. Skipping invite.");
        statusMessages.push({ email, message: "Missing essential invite data" });
        continue;
      }

      console.log("User Type:", userType);
      if (userType === "Admin") {

        const adminInviteSnapshot = await database
          .collection("userInvites")
          .where("invitedBy.companyDetails.companyId", "==", companyId)
          .where("userDetails.email", "==", email)
          .where("invitedAs", "==", "Admin")
          .get();

          if(adminInviteSnapshot.empty) {
            const newInviteRef = await createNewInvite(invite);
            await updateMembersDatabase(email, newInviteRef.id);
            console.log(`Sent new admin invite to ${email}`);
            statusMessages.push({ email, message: "Missing essential invite data" });
            continue;
          }

        const pendingAdminInvites = [];
        const declinedAdminInvites = [];
        const acceptedAdminInvites = [];
        adminInviteSnapshot.forEach((doc) => {
          const data = doc.data();
          if(doc.exists)
          {
            if (
              data.accepted === false &&
              data.declined === false
            ) {
              pendingAdminInvites.push({ id: doc.id, data });
            }
            else if(data.declined === true) {
              declinedAdminInvites.push({ id: doc.id, data });
            }
            else if(data.accepted === true) {
              acceptedAdminInvites.push({ id: doc.id, data });
            }
          }
        });
        if(pendingAdminInvites.length > 0) {
          console.log(`Already invited as admin: ${email}`);
          statusMessages.push({ email, message: "Already invited as Admin (pending)" });
        } else if (acceptedAdminInvites.length > 0) {
          statusMessages.push({ email, message: "This user is already an Admin" });
        }
        else if(declinedAdminInvites.length > 0 && acceptedAdminInvites.length === 0 && pendingAdminInvites.length === 0) {
          const newInviteRef = await createNewInvite(invite);
          await updateMembersDatabase(email, newInviteRef.id);
          console.log(`Invitation sent: ${email}`);
          statusMessages.push({ email, message: "Admin invitation re-sent (previously declined)" });
        }
        continue; // Skip to next invite
      }

      // Flow for Manager/Member (based on teamId and email)
      const { teamId } = invite?.invitedTo || {};
      if (!teamId) {
        console.warn("Missing teamId for Manager/Member. Skipping.");
        statusMessages.push({ email, message: "Missing teamId for Manager/Member" });
        continue;
      }

      const inviteQuerySnapshot = await database
        .collection("userInvites")
        .where("userDetails.email", "==", email)
        .where("invitedTo.teamId", "==", teamId)
        .where("invitedBy.companyDetails.companyId", "==", companyId)
        .get();

      if(inviteQuerySnapshot.empty) {
        const newInviteRef = await createNewInvite(invite);
        await updateMembersDatabase(email, newInviteRef.id);
        console.log(`Invitation sent: ${email}`);
        statusMessages.push({ email, message: "Invitation sent successfully" });
        continue;
      }

      //Check if no action has been taken on the invite, i.e. accepted, declined, if yes then update the invite
      const pendingInvites = [];
      const declinedInvites = [];
      const acceptedInvites = [];
      inviteQuerySnapshot.forEach((doc) => {
        const data = doc.data();
        if(doc.exists)
        {
          if (
            data.accepted === false &&
            data.declined === false
          ) {
            pendingInvites.push({ id: doc.id, data });
          }
          else if(data.declined === true) {
            declinedInvites.push({ id: doc.id, data });
          }
          else if(data.accepted === true) {
            acceptedInvites.push({ id: doc.id, data });
          }
        }
      });

      if (pendingInvites.length > 0) {
        const sameRoleInvite = pendingInvites.find(
          (doc) => doc.data.invitedAs === newRole
        );

        if (sameRoleInvite) {
          console.log(`Pending invite already exists with same role for ${email}. Skipping creation.`);
          statusMessages.push({ email, message: `Pending invite already exists for role ${newRole}` });
          continue;
        } else {
          const inviteId = pendingInvites[0].id;
          const batch = database.batch();
          const ref = database.collection("userInvites").doc(inviteId);
          batch.update(ref, {
            invitedAs: invite.invitedAs,
            invitedTo: invite.invitedTo,
          });
          // Update the existing invite with the new role (invitedAs) and invitedTo        
          await batch.commit();
          console.log(`Updated old invite for ${email} with role ${newRole}`);
          statusMessages.push({ email, message: `Updated existing invite to new role: ${newRole}` });
        }
      } 
      else if(acceptedInvites.length > 0) {
        console.log(`Already accepted invite for ${email}`);
        statusMessages.push({ email, message: `User already accepted invite for role ${newRole}` });
      }
      else if(declinedInvites.length > 0 && acceptedInvites.length === 0 && pendingInvites.length === 0) {
        const newInviteRef = await createNewInvite(invite);
        await updateMembersDatabase(email, newInviteRef.id);
        console.log(`Invitation sent: ${email}`);
        statusMessages.push({ email, message: `Invitation re-sent (previously declined)` });
      }
    }

    return {
      success: true,
      data: statusMessages,
    };
  } catch (e) {
    console.error("Error while adding invites:", e);
    return {
      success: false,
      error: e.message,
      data: statusMessages,
    };
  }
}

// Helper to create new invite
async function createNewInvite(invite) {
  const inviteRef = database.collection("userInvites").doc();
  await inviteRef.set({
    ...invite,
    expired: false,
    declined: false,
    accepted: false,
    // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
  console.log(`Created new invite for ${invite.userDetails.email}`);
  return inviteRef;
}

// Helper to update membersDatabase
async function updateMembersDatabase(email, inviteRefId) {
  try {
    const memberDataSet = await database
      .collection("membersDatabase")
      .where("accountEmailid", "==", email)
      .get();

    const memberDocIds = memberDataSet.docs.map((doc) => doc.id);
    if (!memberDocIds.length) return;

    await database
      .collection("membersDatabase")
      .doc(memberDocIds[0])
      .update({
        teamInvites: firebase.firestore.FieldValue.arrayUnion(inviteRefId),
      });

    console.log(`Updated membersDatabase for ${email}`);
  } catch (error) {
    console.log("Error updating membersDatabase: ", error);
  }
}

export default {
  getActiveTeamID,
  getTeamNames,
  getTimes,
  getWeekData,
  get7DaysTimes,
  getActiveTeam,
  getTeamMembers,
  getUserTeamCollection,
  getManagerTeamCollection,
  getMemberTeamCollection,
  getMembersTimeLog,
  getAllMembers,
  getAllTeamMembers,
  getExistedEligibleUsers,
  addInvitesToDatabase,
  getFinalizeInvitationData,
  getFinalizedProjectInvitation,
  getAllTeamTimeLog,
  getAllTeamTimeData,
  getDateRangeTimeData,
  getAllTeamWeekTimeData,
  getAllTeamMonthTimeData,
  addProject,
  getProjects,
  getTopProjects,
  getProjectId,
  getTaskList,
  addTaskList,
  updateProject,
  deleteProject,
  getTeamData,
  getTeamsId,
  getCompaniesId,
  getTopData,
  // setActiveTeamId,
  setThreshold,
  getThreshold,
};
